<template>
  <div class="container">
    <div class="card">
      <div class="title-container">
        <img
          src="../assets/logo.png"
          alt="Socialeasy"
          width="164px"
          height="100px"
        />
        <h2 class="title">Create account</h2>
      </div>
      <div class="input-container">
        <label for="display-name">Display name</label>
        <input type="text" id="display-name" v-model="displayName" />
      </div>
      <div class="input-container">
        <label for="username">Username</label>
        <input type="text" id="username" v-model="username" />
      </div>
      <div class="input-container">
        <label for="email">Email</label>
        <input type="email" id="email" v-model="email" />
      </div>
      <div class="input-container">
        <label for="password">Password</label>
        <input type="password" id="password" v-model="password" />
      </div>

      <p class="error" v-if="error">{{ error }}</p>

      <button @click="signUp()">
        Create account
      </button>
      <div class="login-container">
        <p>Already have an account?</p>
        <p>
          <router-link class="login-link" to="/login"> Login</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      displayName: '',
      username: '',
      email: '',
      password: '',
      error: null,
    };
  },
  computed: {
    ...mapState(['loading']),
  },
  methods: {
    signUp() {
      try {
        if (!this.displayName) {
          throw new Error('Display name required');
        }
        if (!this.username) {
          throw new Error('Username required');
        }
        if (!this.email) {
          throw new Error('Email required');
        }
        if (!this.password) {
          throw new Error('Password required');
        }

        if (this.email && this.password) {
          this.$store.dispatch('signUp', {
            displayName: this.displayName,
            username: this.username,
            email: this.email,
            password: this.password,
          });
        }
      } catch (error) {
        this.error = error.message;
      }
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  width: 312px;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 0;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}

input {
  background-color: rgb(228, 228, 228);
  border: none;
  border-radius: 6px;
  padding: 0 8px;
  height: 2rem;
}

.login-container {
  display: flex;
  justify-content: center;
}

button {
  height: 2rem;
  border-radius: 6px;
  margin: 16px 0;
  font-size: 16px;
  font-weight: 500;
}

p {
  margin: 2px;
  font-size: 14px;
}
</style>
