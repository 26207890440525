export function getImage(type) {
  switch (type.toLowerCase()) {
    case 'instagram':
      return require('../assets/static/images/instagram.png');
    case 'snapchat':
      return require('../assets/static/images/snapchat.png');
    case 'facebook':
      return require('../assets/static/images/faceboook.png');
    default:
      break;
  }
}

export function getHref(type) {
  switch (type.toLowerCase()) {
    case 'instagram':
      return 'https://www.instagram.com/';
    case 'snapchat':
      return 'https://www.snapchat.com/add/';
    case 'facebook':
      return 'https://www.facebook.com/';
    default:
      break;
  }
}
