<template>
  <div class="container">
    <div class="add-social">
      <div class="row">
        <button
          class="select-social-media-container"
          @click="toggleSelectSocialModal"
        >
          <div v-if="item.type == ''" class="select-social-media">
            <p>Select social media</p>
            <i class="fas fa-chevron-down"></i>
          </div>

          <div v-else class="select-social-media">
            <img
              class="type-image"
              v-bind:src="getImage(item.type)"
              height="24px"
              width=" 24px"
            />
            <p v-bind:style="{ marginRight: '20px', marginLeft: '12px' }">
              {{ item.type }}
            </p>
          </div>
        </button>

        <SelectSocialModal
          v-show="selectSocialModalOpen"
          @close="toggleSelectSocialModal"
          :options="options"
          v-on:select="onSelect"
        />
        <i class="fas fa-times fa-xs" id="close" @click="$emit('toggle')"></i>
      </div>
      <div class="row">
        <input
          ref="input"
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          v-model="item.name"
        />
        <button class="add-social-media" @click="$emit('addSocial', item)">
          Add
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SelectSocialModal from './SelectSocialModal.vue';
import { getImage } from '../utils/functions';

export default {
  name: 'AddSocial',
  components: {
    SelectSocialModal,
  },
  data() {
    return {
      selectSocialModalOpen: false,
      item: {
        name: '',
        type: '',
      },
      options: ['Instagram', 'Snapchat', 'Facebook'],
    };
  },
  methods: {
    toggleSelectSocialModal() {
      this.selectSocialModalOpen = !this.selectSocialModalOpen;
    },
    onSelect(value) {
      this.item.type = value;
    },
    getImage(type) {
      return getImage(type);
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 0;
  padding-top: 4px;
}

.edit-profile {
  background-color: transparent;
  color: rgb(163, 163, 163);
}

.edit-profile i {
  margin-right: 4px;
}

.add-social {
  display: flex;
  flex-direction: column;
  background-color: #292929;
  padding-right: 12px;
  padding-top: 12px;
  width: 232px;
  height: 88px;
  border-radius: 6px;
}

.add-social-media {
  border-radius: 4px;
  width: 56px;
}

.select-social-media-container {
  width: 65%;
  margin-right: 10px;
  color: white;
  background-color: #313131;
  margin: 0 0 10px 14px;
  border-radius: 6px;
  height: 36px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-social-media {
  display: flex;
  align-items: center;
}

.select-social-media i {
  margin-left: 6px;
}

.row {
  display: flex;
  justify-content: space-between;
}

#close {
  cursor: pointer;
}

#close:hover {
  opacity: 0.9;
}

#close:active {
  opacity: 0.7;
}

input {
  width: 58%;
  margin-right: 10px;
  color: white;
  background-color: #313131;
  padding: 4px 8px;
  margin-left: 14px;
  border-radius: 6px;
  height: 20px;
}

.type-image {
  border-radius: 6px;
}

h5 {
  font-weight: 100;
  margin-bottom: 4px;
}
</style>
