<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="modal-header" id="modalTitle">
          Select social media
          <i class="fas fa-times fa-xs" id="close" @click="close"></i>
        </div>

        <div class="modal-body" id="modalDescription">
          <div>
            <img
              v-for="item in options"
              :key="item"
              v-bind:src="getImage(item)"
              height="50px"
              width="50px"
              @click="select(item)"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { getImage } from '../utils/functions';

export default {
  name: 'SelectSocialModal',
  props: {
    options: { type: Array },
  },
  data() {
    return {
      selected: '',
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    getImage(type) {
      return getImage(type);
    },
    select(item) {
      this.$emit('select', item);
      this.close();
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #292929;
  margin-bottom: 124px;
  border-radius: 6px;
  width: 264px;
  height: 200px;
}

.modal-header {
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  height: 68px;
}

img {
  border-radius: 12px;
  margin: 5px;
}

img:hover {
  opacity: 0.8;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active {
  transition: opacity 0.3s ease;
}

i {
  cursor: pointer;
}

i:hover {
  opacity: 0.9;
}

i:active {
  opacity: 0.7;
}
</style>
