<template>
  <div class="container">
    <h3>Requests</h3>
    <p v-if="loading">Loading socials...</p>
    <div class="requests" v-else-if="friendRequests.length">
      <div v-for="request in friendRequests" v-bind:key="request.id">
        <div class="request">
          <p>{{ request.requester }}</p>
          <button
            v-if="request.status == 'PENDING'"
            @click="handleFriendRequest(request, 'accept')"
          >
            Accept
          </button>
          <button @click="handleFriendRequest(request, 'decline')">
            Decline
          </button>
        </div>
      </div>
    </div>
    <p v-else>You don't have any friend requests</p>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'FriendRequestList',
  data() {
    return {
      friendRequests: [],
      loading: null,
    };
  },
  created() {
    this.getFriendRequests();
  },
  methods: {
    async getFriendRequests() {
      this.loading = true;
      const friendRequestsRef = await firebase
        .firestore()
        .collection('friend-requests')
        .where('recipientId', '==', firebase.auth().currentUser.uid)
        .get();

      if (friendRequestsRef.exists) {
        this.friendRequests = friendRequestsRef.data();
      }
      this.loading = false;
    },
    async handleFriendRequest(req, status) {
      let user;
      const db = firebase.firestore();
      const friendRequest = db.collection('friend-requests').doc(req.id);

      try {
        await db.runTransaction(async () => {
          if (status === 'accept') {
            // add row to requesters friends
            const recipientRef = db
              .collection('friends')
              .doc(firebase.auth().currentUser.uid);

            user = {
              id: req.requesterId,
              name: req.requester,
            };
            if (!(await recipientRef.get()).exists) {
              await recipientRef.set({
                users: [user],
              });
            } else {
              await recipientRef.update({
                users: firebase.firestore.FieldValue.arrayUnion(user),
              });
            }

            // add row to recipients friends
            const recieverRef = db.collection('friends').doc(req.requesterId);

            user = {
              id: req.recipientId,
              name: req.recipient,
            };
            if (!(await recieverRef.get()).exists) {
              await recieverRef.set({
                users: [user],
              });
            } else {
              await recieverRef.update({
                users: firebase.firestore.FieldValue.arrayUnion(user),
              });
            }
          }

          // delete friend request
          friendRequest.delete();
          this.friendRequets = this.friendRequests.filter(
            item => item.id != req.id
          );
        });

        console.log('Transaction success!');
      } catch (e) {
        console.log('Transaction failure:', e);
      }
    },
  },
};
</script>

<style scoped></style>
