<template>
  <div class="container">
    <UserProfile :user="user" :loading="userLoading" />

    <div v-if="isFriend">
      <p>You are friends</p>
    </div>
    <div v-else-if="user.uid !== currentUid">
      <button @click="sendFriendRequest()">
        Add friend
      </button>
    </div>

    <p v-if="loading">Loading socials...</p>
    <div v-else-if="socials.length">
      <div
        class="item"
        v-for="item in socials"
        :key="item.id"
        @click="openLink(item)"
      >
        <img v-bind:src="getImage(item.type)" />
        <p class="list-item">{{ item.name }}</p>
      </div>
    </div>
    <div v-else>
      <p class="no-results">
        {{ user.displayName }} has not added any socials yet!
      </p>
    </div>
  </div>
</template>

<script>
import { getImage, getHref } from '../utils/functions';
import firebase from 'firebase';
import UserProfile from '../components/UserProfile.vue';

export default {
  name: 'Socials',
  components: {
    UserProfile,
  },
  data() {
    return {
      user: {},
      image: null,
      userLoading: null,
      socials: [],
      socialsLoading: null,
      error: null,
      isFriend: null,
    };
  },
  computed: {
    currentUid: function() {
      return firebase.auth().currentUser.uid;
    },
  },
  created() {
    this.getUser();
    this.getSocials(this.$route.params.id);
    this.checkIfFriend();
  },
  methods: {
    async getUser() {
      this.userLoading = true;
      try {
        const userData = await firebase
          .firestore()
          .collection('users')
          .doc(this.$route.params.id)
          .get();

        if (!userData.data()) {
          throw new Error("User doesn't exist");
        }

        await this.getProfilePhoto();

        this.user = {
          uid: userData.id,
          ...userData.data(),
          image: this.image,
        };
      } catch (error) {
        this.error = error.message;
      } finally {
        this.userLoading = false;
      }
    },
    getImage(type) {
      return getImage(type);
    },
    getHref(type) {
      return getHref(type);
    },
    openLink(item) {
      const href = getHref(item.type) + item.name;
      window.open(href);
    },
    async getProfilePhoto() {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child(
        `photos/${this.$route.params.id}/avatar.png`
      );

      await imageRef
        .getDownloadURL()
        .then(url => {
          this.image = url;
        })
        .catch(() => {
          this.image = require('../assets/static/images/avatar.png');
        });
    },
    async sendFriendRequest() {
      try {
        // TODO: only add if document doesnt exist
        await firebase
          .firestore()
          .collection('friend-requests')
          .add(
            {
              sent: new Date(),
              requesterId: this.userProfile.uid,
              requester: this.userProfile.displayName,
              recipientId: this.user.uid,
              recipient: this.user.displayName,
              status: 'PENDING',
            },
            { merge: true }
          );
      } catch (error) {
        console.log(error);
      }
    },
    async getSocials(id) {
      this.socialsLoading = true;
      try {
        const socialRef = await firebase
          .firestore()
          .collection('socials')
          .where('userId', '==', id)
          .get();

        if (!socialRef.empty) {
          socialRef.forEach(doc => {
            let social = doc.data();
            social.id = doc.id;

            this.socials.push(social);
          });
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        this.socialsLoading = false;
      }
    },
    async checkIfFriend() {
      const friends = await firebase
        .firestore()
        .collection('friends')
        .doc(firebase.auth().currentUser.uid)
        .get();

      if (!friends.exists) {
        this.isFriend = false;
      }

      if (friends.data().users.some(item => item.id == this.$route.params.id)) {
        this.isFriend = true;
      }
    },
    async hasFriendRequestPending() {
      const friendRequest = await firebase
        .firestore()
        .collection('friend-requests')
        .where('')
        .get();

      console.log(friendRequest);
    },
  },
};
</script>

<style scoped>
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-photo {
  object-fit: cover;
  border-radius: 64px;
}

.item {
  background-color: #292929;
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: 232px;
  height: 40px;
  margin: 8px;
}

.item:hover {
  opacity: 0.8;
  cursor: pointer;
}

.item img {
  height: 40px;
  width: 40px;
  border-radius: 10px;
}

p {
  color: white;
  text-align: center;
}

i {
  cursor: pointer;
  padding-right: 12px;
}

i:hover {
  opacity: 0.9;
}

i:active {
  opacity: 0.7;
}

.no-results {
  margin: 12px 0;
  text-align: center;
}

.list-item {
  margin-left: 12px;
  text-align: left;
}

h5 {
  font-weight: 100;
  margin-bottom: 4px;
}
</style>
