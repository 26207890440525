<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="modal-header" id="modalTitle">
          Edit profile
          <i class="fas fa-times fa-xs" id="close" @click="close"></i>
        </div>

        <div class="modal-body" id="modalDescription">
          <div class="input">
            <label for="username">Username</label>
            <input
              type="text"
              name="username"
              id="username"
              placeholder="Username"
              v-model="username"
            />
          </div>
          <div class="input">
            <label for="name">Display name</label>
            <input
              type="text"
              name="name"
              id="displayName"
              placeholder="Display name"
              v-model="displayName"
            />
          </div>

          <!-- <p class="error" v-if="error">{{ error }}</p> -->
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn-save"
            aria-label="Save modal"
            @click="updateProfile"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'EditProfileModal',
  props: {
    userId: {
      required: true,
    },
  },
  data() {
    return {
      username: '',
      displayName: '',
      error: '',
    };
  },
  methods: {
    close() {
      this.$emit('close');
      this.error = '';
    },
    async updateProfile() {
      try {
        if (!this.displayName) {
          throw new Error('Please enter a display name');
        }

        const user = {
          uid: this.userId,
          displayName: this.displayName,
        };

        this.$store
          .dispatch('updateProfile', user)
          .then((this.displayName = ''))
          .then(this.close())
          .then((this.error = ''));
      } catch (error) {
        this.error = error.message;
      }
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #292929;
  margin-bottom: 124px;
  border-radius: 6px;
  width: 264px;
  height: 300px;
}

.modal-header,
.modal-footer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 164px;
}

.input {
  margin-bottom: 12px;
}

label {
  font-size: 14px;
}

input[type='text'] {
  margin: 4px 0;
  color: white;
  background-color: #313131;
  padding: 12px 6px;
  border-radius: 6px;
  width: 95%;
}

.modal-footer {
  flex-direction: row;
  justify-content: space-between;
}

.btn-close {
  background-color: transparent;
  border: 1px solid #3eb489;
  border-radius: 6px;
  width: 64px;
  height: 30px;
}

.btn-save {
  border-radius: 6px;
  width: 64px;
  height: 30px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active {
  transition: opacity 0.3s ease;
}

i {
  cursor: pointer;
}

i:hover {
  opacity: 0.9;
}

i:active {
  opacity: 0.7;
}

.error {
  margin: 8px 0px 0px 0px;
  font-size: 14px;
}
</style>
