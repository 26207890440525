import Vue from 'vue';
import App from './App.vue';
import router from './router';
import firebase from 'firebase/app';
import store from './store/index';
import './assets/main.css';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// // utils
// const db = firebase.firestore();
// const auth = firebase.auth();

// // collection references
// const users = db.collection('users');

Vue.config.productionTip = false;

let app;
firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  }
  if (user) {
    store.dispatch('fetchUserProfile', user.uid);
  }
});

// export { db, auth, users };
