<template>
  <div class="container">
    <FriendsList />
    <FriendRequestList />
  </div>
</template>

<script>
import FriendsList from '../components/FriendsList.vue';
import FriendRequestList from '../components/FriendRequestList.vue';

export default {
  name: 'Friends',
  components: {
    FriendsList,
    FriendRequestList,
  },
};
</script>

<style scoped></style>
