<template>
  <div class="container">
    <div class="card">
      <div class="title-container">
        <img
          src="../assets/logo.png"
          alt="Socialeasy"
          width="164px"
          height="100px"
        />
        <h2 class="title">Reset password</h2>
      </div>
      <div class="input-container">
        <label for="email">Email</label>
        <input type="email" id="email" v-model="email" />
      </div>
      <p class="error" v-if="error">{{ error }}</p>
      <button @click="resetPassword()">
        Login
      </button>
    </div>
    <p>
      <router-link to="/login">Back to login</router-link>
    </p>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'ResetPassword',
  data() {
    return {
      email: '',
      error: '',
    };
  },
  methods: {
    async resetPassword() {
      try {
        if (!this.email) {
          throw new Error('Email required');
        }

        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .then((this.error = ''), (this.email = ''));
      } catch (error) {
        this.error = error.message;
      }
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  width: 312px;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}

input {
  background-color: rgb(228, 228, 228);
  border: none;
  border-radius: 6px;
  padding: 0 8px;
  height: 2rem;
}

button {
  height: 2rem;
  border-radius: 6px;
  margin: 16px 0;
  font-size: 16px;
  font-weight: 500;
}

p {
  margin: 0;
  font-size: 14px;
}
</style>
