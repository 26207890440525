<template>
  <div class="container">
    <h3>Friends</h3>
    <p v-if="loading">Loading socials...</p>
    <div class="friends" v-else-if="friends.length">
      <div v-for="friend in friends" v-bind:key="friend.id">
        <div class="friend">
          <router-link :to="{ name: 'Socials', params: { id: friend.id } }">{{
            friend.name
          }}</router-link>
        </div>
      </div>
    </div>
    <p v-else>You don't have any friend</p>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'FriendsList',
  data() {
    return {
      friends: [],
      loading: null,
    };
  },
  created() {
    this.getFriends();
  },
  methods: {
    async getFriends() {
      this.loading = true;
      const friendsRef = await firebase
        .firestore()
        .collection('friends')
        .doc(firebase.auth().currentUser.uid)
        .get();

      if (friendsRef.exists) {
        this.friends = friendsRef.data().users;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
}
</style>
