<template>
  <div class="nav">
    <router-link to="/friends">
      <i class="fas fa-user-friends"></i> Friends</router-link
    >
    <router-link to="/"> <i class="fas fa-qrcode"></i> QR-Code</router-link>
    <router-link to="/profile">
      <i class="fas fa-user"></i> Profile</router-link
    >
  </div>
</template>

<script>
export default {
  name: 'Navbar',
};
</script>

<style scoped>
.nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  margin: 0 4px;
}

a {
  margin: 0 12px;
}

.router-link-exact-active {
  font-weight: bold;
  color: #5edbad;
}
</style>
