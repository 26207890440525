import router from '../router';
import firebase from 'firebase';

const actions = {
  // AUTH ACTIONS
  async login({ commit, dispatch }, form) {
    commit('loading', true);

    try {
      const { user } = await firebase
        .auth()
        .signInWithEmailAndPassword(form.email, form.password);

      dispatch('fetchUserProfile', user.uid);
    } catch (error) {
      if (error.code == 'auth/invalid-email') {
        commit('setError', error.message);
      } else {
        commit('setError', 'Email or password is incorrect');
      }
    }
  },
  async signUp({ commit, dispatch }, form) {
    commit('loading', true);

    try {
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(form.email, form.password);

      // create user object in userCollections
      await firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({
          displayName: form.displayName,
          username: form.username,
          email: form.email,
        });

      dispatch('fetchUserProfile', user.uid);
    } catch (error) {
      if (error.code == 'auth/invalid-email') {
        commit('setError', error.message);
      } else if (error.code == 'auth/weak-password') {
        commit('setError', error.message);
      } else {
        console.log(error.code);
        commit('setError', 'Something went wrong creating an account');
      }
    }
  },
  async updateProfile({ dispatch }, user) {
    const userRef = firebase
      .firestore()
      .collection('users')
      .doc(user.uid);

    await userRef.update({
      displayName: user.displayName,
    });

    dispatch('fetchUserProfile', user.uid);
  },
  async updateProfilePhoto({ dispatch }, photoUrl) {
    const uid = firebase.auth().currentUser.uid;
    const path = `photos/${uid}/avatar.png`;

    try {
      const storageRef = firebase.storage().ref();

      const imageRef = storageRef.child(path);

      await imageRef.put(photoUrl);
    } catch (error) {
      console.log(error.message);
    }

    dispatch('fetchUserProfile', uid);
  },
  async fetchUserProfile({ commit }, uid) {
    commit('loading', true);
    // fetch user profile
    const userProfile = await firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get();

    const userData = userProfile.data();
    userData.uid = uid;

    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`photos/${uid}/avatar.png`);

    await imageRef
      .getDownloadURL()
      .then(url => {
        userData.image = url;
      })
      .catch(error => {
        console.log(error.message);
        userData.image = require('../assets/static/images/avatar.png');
      });
    // set user profile in state
    commit('setUserProfile', userData);
    commit('loading', false);

    // change route
    if (
      router.currentRoute.path === '/login' ||
      router.currentRoute.path === '/sign-up'
    ) {
      router.push('/');
    }
  },
  async logout({ commit }) {
    // log user out
    await firebase.auth().signOut();

    // clear user data from state
    commit('setUserProfile', {});

    // redirect to login view
    router.push('/login');
  },
};

export default actions;
