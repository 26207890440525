<template>
  <div class="container">
    <UserProfile :user="userProfile" :loading="loading" />
    <button class="edit-profile" @click="toggleEditProfileModal()">
      <i class="fas fa-user-edit fa-xs"></i>Edit profile
    </button>
    <!-- TODO: Refactor to inline edit -->
    <EditProfileModal
      v-show="editProfileModalOpen"
      @close="toggleEditProfileModal()"
      :userId="userProfile.uid"
    />
    <SocialsList />
    <button class="logout" @click="logout()">
      Logout <i class="fas fa-sign-out-alt"></i>
    </button>
  </div>
</template>

<script>
import UserProfile from '../components/UserProfile.vue';
import SocialsList from '../components/SocialsList.vue';
import EditProfileModal from '../components/EditProfileModal.vue';
import { mapState } from 'vuex';

export default {
  name: 'Profile',
  components: {
    UserProfile,
    SocialsList,
    EditProfileModal,
  },
  data() {
    return {
      editProfileModalOpen: false,
    };
  },
  computed: {
    ...mapState(['userProfile', 'loading']),
  },
  methods: {
    toggleEditProfileModal() {
      this.editProfileModalOpen = !this.editProfileModalOpen;
    },
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 0;
  padding-top: 4px;
}

.edit-profile {
  background-color: transparent;
  color: rgb(163, 163, 163);
}

.edit-profile i {
  margin-right: 4px;
}

.logout {
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.logout i {
  margin-left: 6px;
}

.type-image {
  border-radius: 6px;
}

h5 {
  font-weight: 100;
  margin-bottom: 4px;
}
</style>
