<template>
  <div class="container">
    <p v-if="loading">Loading profile...</p>
    <div v-else class="profile-container">
      <div class="avatar">
        <label v-if="user.uid == currentUid" class="file-label" for="actual-btn"
          ><i class="fas fa-plus-circle fa-lg"></i
        ></label>
        <img
          class="profile-photo"
          v-bind:src="user.image"
          width="100px"
          height="100px"
        />
      </div>
      <h3>{{ user.displayName }}</h3>
      <h5 v-if="user.username">@{{ user.username }}</h5>
      <input
        v-if="user.uid == currentUid"
        type="file"
        id="actual-btn"
        @change="onFileSelected"
        accept="image/*"
        hidden
      />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'AddSocial',
  props: {
    user: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    currentUid: function() {
      return firebase.auth().currentUser.uid;
    },
  },
  methods: {
    onFileSelected(event) {
      const image = event.target.files[0];
      this.$store.dispatch('updateProfilePhoto', image);
    },
  },
};
</script>

<style scoped>
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-photo {
  object-fit: cover;
  border-radius: 64px;
}

.file-label {
  color: white;
  cursor: pointer;
  margin-top: 74px;
  margin-left: 6px;
  position: absolute;
}
</style>
