<template>
  <div class="container">
    <div class="card">
      <div class="title-container">
        <img
          src="../assets/logo.png"
          alt="Socialeasy"
          width="164px"
          height="100px"
        />
        <h2 class="title">Login</h2>
      </div>
      <div class="input-container">
        <label for="email">Email</label>
        <input type="email" id="email" v-model="email" />
      </div>
      <div class="input-container">
        <label for="password">Password</label>
        <input type="password" id="password" v-model="password" />
      </div>
      <p class="error" v-if="error">{{ error }}</p>
      <button @click="login()">
        Login
      </button>
      <div class="register-container">
        <p>Don't have an account?</p>
        <p>
          <router-link to="/sign-up"> Create account</router-link>
        </p>
      </div>
    </div>
    <p>
      <router-link to="/reset-password">Forgot your password?</router-link>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: null,
    };
  },
  computed: {
    ...mapState(['loading']),
  },
  methods: {
    async login() {
      try {
        if (!this.email && !this.password) {
          throw new Error('Email and password required');
        }
        if (!this.email) {
          throw new Error('Email required');
        }
        if (!this.password) {
          throw new Error('Password required');
        }

        if (this.email && this.password) {
          this.$store.dispatch('login', {
            email: this.email,
            password: this.password,
          });
        }
      } catch (error) {
        this.error = error.message;
      }
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  width: 312px;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 0;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}

input {
  background-color: rgb(228, 228, 228);
  border: none;
  border-radius: 6px;
  padding: 0 8px;
  height: 2rem;
}

.register-container {
  display: flex;
  justify-content: center;
}

button {
  height: 2rem;
  border-radius: 6px;
  margin: 16px 0;
  font-size: 16px;
  font-weight: 500;
}

p {
  margin: 2px;
  font-size: 14px;
}
</style>
