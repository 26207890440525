<template>
  <div class="container">
    <button class="add-social-button" @click="toggle()">
      <i v-if="isVisible" class="fas fa-minus"></i>
      <i v-else class="fas fa-plus"></i>
      Add social
    </button>
    <p class="error" v-if="error">{{ error }}</p>

    <AddSocial
      v-if="isVisible"
      v-on:addSocial="addSocial"
      v-on:toggle="toggle"
    />

    <p v-if="loading">Loading socials...</p>
    <div v-else-if="socials.length">
      <div class="item" v-for="item in socials" :key="item.id">
        <img v-bind:src="getImage(item.type)" />
        <a v-bind:href="getHref(item.type) + item.name" target="_blank">{{
          item.name
        }}</a>
        <i class="fas fa-times" @click="deleteSocial(item.id)"></i>
      </div>
    </div>
    <div v-else>
      <p class="no-results">You haven't added any socials yet</p>
    </div>
  </div>
</template>

<script>
import { getImage, getHref } from '../utils/functions';
import AddSocial from './AddSocial.vue';
import firebase from 'firebase';

export default {
  name: 'SocialsList',
  components: {
    AddSocial,
  },
  data() {
    return {
      socials: [],
      isVisible: false,
      loading: null,
      error: null,
    };
  },
  created() {
    this.getSocials();
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
      this.error = null;
    },
    async getSocials() {
      this.loading = true;
      try {
        const socialRef = await firebase
          .firestore()
          .collection('socials')
          .where('userId', '==', firebase.auth().currentUser.uid)
          .get();

        if (!socialRef.empty) {
          socialRef.forEach(doc => {
            let social = doc.data();
            social.id = doc.id;

            this.socials.push(social);
          });
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        this.loading = false;
      }
    },
    async addSocial(item) {
      try {
        if (!item.type) {
          throw new Error('No social selected');
        }

        if (!item.name) {
          throw new Error('Please enter a name');
        }

        const social = {
          createdAt: new Date(),
          type: item.type.toLowerCase(),
          name: item.name,
          userId: firebase.auth().currentUser.uid,
        };

        await firebase
          .firestore()
          .collection('socials')
          .add(social)
          .then(i => this.socials.push({ id: i.id, ...social }))
          .then((this.isVisible = false))
          .then(((item.type = ''), (item.name = '')))
          .then((this.error = null));
      } catch (error) {
        this.error = error.message;
      }
    },
    async deleteSocial(id) {
      await firebase
        .firestore()
        .collection('socials')
        .doc(id)
        .delete();

      this.socials = this.socials.filter(item => item.id != id);
    },
    getImage(type) {
      return getImage(type);
    },
    getHref(type) {
      return getHref(type);
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 4px;
}

.add-social-button {
  background-color: transparent;
  border: solid 1px;
  border-radius: 8px;
  margin: 12px;
  color: #3eb489;
}

.item {
  display: grid;
  grid-template-columns: 1fr 4fr 20px;
  justify-content: space-between;
  align-items: center;
  background-color: #292929;
  margin: 12px;
  border-radius: 6px;
  width: 232px;
}

img {
  height: 40px;
  width: 40px;
  border-radius: 10px;
}

a {
  color: white;
  margin-left: 12px;
}

i {
  cursor: pointer;
}

i:hover {
  opacity: 0.9;
}

i:active {
  opacity: 0.7;
}

.error {
  font-size: 14px;
}
</style>
