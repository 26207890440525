<template>
  <div class="container">
    <NavBar v-if="this.checkUser()" />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from './components/NavBar.vue';

export default {
  components: { NavBar },
  name: 'App',
  data() {
    return {
      isLoggedIn: false,
      currentUser: false,
    };
  },
  computed: {
    ...mapState(['userProfile']),
  },
  methods: {
    checkUser() {
      if (Object.keys(this.userProfile).length !== 0) {
        return true;
      }
    },
  },
};
</script>
