const mutations = {
  setUserProfile(state, val) {
    state.userProfile = val;
  },
  loading(state, val) {
    state.loading = val;
  },
  setError(state, val) {
    state.error = val;
  },
  resetError(state) {
    state.error = null;
  },
};

export default mutations;
